




































































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import { Connection, ConnectionCategory, Providers } from '@/api-svc-types';
import UiButton from '@/components/ui/UiButton.vue';
import UiDatePicker2 from '@/components/ui/UiDatePicker2.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';

import { BaseVue } from '../../BaseVue';
import UiPageHeader from '../ui/UiPageHeader.vue';
import ReportHTMLToCSV from './ReportHTMLToCSV.vue';

type ReportType = 'JournalReport' | 'RolledUpJournalReport';

export type ReportSettings = {
  startDate: string | null;
  endDate: string | null;
  connectionId: string | null;
  groupBy?: string | null;
};

@Component({
  components: {
    UiButton,
    UiDatePicker2,
    UiSelect2,
    ReportHTMLToCSV,
    UiPageHeader,
  },
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            category
            name
            isDeleted
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        if (data && data.connections) {
          return data.connections.filter(
            (x: Connection) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection
          );
        } else {
          return [];
        }
      },
    },
  },
})
export default class JournalReportSettings extends BaseVue {
  @Prop({ required: true })
  public reportType!: ReportType;

  @Prop({ default: false })
  public isLoading!: boolean;

  @Prop({ default: null })
  public reportDoc!: any;

  @Prop({ default: false })
  public hasError!: boolean;

  @Prop({ default: () => [] })
  public errors!: string[];

  @Prop()
  public reportElapsedTime?: string;

  public startDate: string | null = null;
  public endDate: string | null = null;
  public groupBy = 'Contact';
  public connectionId: string | null = null;

  private connections: any[] = [];

  public reportMapping: { [key in ReportType]: any } = {
    JournalReport: {
      name: 'Journal Entry Report',
      tip: '_journalReport',
      repeatIndexes: [0, 1, 2, 4, 5],
      reportTotalIndex: 5,
      removeSubtotal: true,
      subtotalIndex: 4,
    },
    RolledUpJournalReport: {
      name: 'Rolled Up Journal Entry Report',
      tip: '_rolledUpJEReport',
      repeatIndexes: [0],
      reportTotalIndex: 2,
      removeSubtotal: false,
      subtotalIndex: undefined,
    },
  };

  public get connectionList() {
    const categories = this.$store.getters['categories/CATEGORIES'];
    const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];

    let connections = this.connections;

    if (
      (contacts?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) ||
      (categories?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false)
    ) {
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
      };
      connections = connections.concat(manualAccountingConnection);
    }

    if (connections && connections.length > 0) {
      return connections;
    } else {
      return [{ provider: 'None', id: 'none' }];
    }
  }

  public get runReportBtnDisabled() {
    return !this.startDate || !this.endDate || !this.connectionId || this.isLoading;
  }

  public get currentTimezone() {
    return this.$store.state.currentOrg.timezone ? this.$store.state.currentOrg.timezone : 'N/A';
  }

  public disabledStartDates(date: Date) {
    return !!this.endDate && date > new Date(this.endDate + ' 00:00:00');
  }

  public disabledEndDates(date: Date) {
    return !!this.startDate && date < new Date(this.startDate + ' 00:00:00');
  }

  @Watch('startDate')
  @Watch('endDate')
  @Watch('connectionId')
  @Watch('groupBy')
  onFormChange() {
    const reportSettings: ReportSettings = {
      startDate: this.startDate,
      endDate: this.endDate,
      connectionId: this.connectionId,
    };

    if (this.reportType === 'RolledUpJournalReport') {
      reportSettings.groupBy = this.groupBy;
    }

    this.$emit('settings-update', reportSettings);
  }
}
